.reviews-container {
    background-color: #1A1A1A;
    color: #ffffff;
    padding: 40px;
    text-align: center;
  }
  
  .reviews-container h2 {
    font-weight: 800;
    font-size: 1.4em;
    margin-top: 5px;
    margin-bottom: 35px;
    color: #ffffff;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .reviews-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards por linha em telas grandes */
    gap: 20px;
    align-items: stretch; /* Garante que os cards tenham alturas iguais */
  }
  
  .review-card {
    background-color: #202020;
    padding: 20px;
    border-radius: 30px;
    text-align: left;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
    display: flex;
    flex-direction: column; /* Garante alinhamento vertical dos elementos internos */
    justify-content: space-between; /* Distribui bem os elementos no card */
  }
  
  .stars {
    margin-bottom: 10px;
  }
  
  .star-purple {
    color: #9F63DD;
    font-size: 1.5em;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .review-text {
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 15px;
    color: #ffffff;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  /* Grupo do nome e função */
  .review-author-container {
    display: flex;
    flex-direction: column; /* Alinhamento vertical do nome e função */
    align-items: flex-start; /* Ambos os textos alinhados à esquerda */
    margin-top: auto; /* Garante que esta seção fique no final do card */
  }
  
  .review-author {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 0px; /* Espaço controlado entre o nome e a função */
    line-height: 1.2;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .review-role {
    font-size: 0.9em;
    color: #bbbbbb;
    margin-top: 5px;
    line-height: 1.2;
    text-align: left;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .reviews-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 cards por linha */
      gap: 15px;
      align-items: stretch; /* Mantém a altura igual dos cards */
    }
  }
  
  @media (max-width: 480px) {
    .reviews-grid {
      grid-template-columns: 1fr; /* 1 card por linha */
      gap: 20px;
    }
  }
  