@font-face {
  font-family: 'octopus'; /* Nome que você escolher */
  src: url('../fonts/octopus.otf') format('truetype');
}

body {
  font-family: "Inter", sans-serif;
  background-color: #1A1A1A;
  color: #ffffff;
}

.cards-container {
  display: flex;
  justify-content: center; /* Centraliza os cards */
  align-items: stretch;
  flex-wrap: wrap; /* Permite que os cards quebrem linha */
  padding: 20px; /* Espaçamento interno do contêiner */
  gap: 50px; /* Define o espaço entre os cards */
  background-color: #1A1A1A; /* Fundo escuro */
}

.card {
  background-color: #202020; /* Fundo do card */
  border-radius: 30px;
  padding: 20px;
  color: white;
  text-align: center;
  width: 400px;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Distribui o conteúdo uniformemente */
  align-items: center; /* Centraliza o conteúdo */
  height: 470px; /* Altura fixa para alinhar todos os cards */
}

.card-icon {
  margin-bottom: -10px; /* Espaço entre a imagem e o título */
  flex-shrink: 0;
}

.card-icon img {
  width: 500px; /* Tamanho padrão da imagem */
  height: 150px; /* Altura padrão para manter proporção */
  object-fit: contain; /* Garante que a imagem se ajuste sem distorção */
  border-radius: 0%; /* Deixa a imagem quadrada */
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
}

.card-title {
  font-weight: 800;
  font-size: 1.5rem;
  color: #9F63DD;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  margin-top: 10px;
  margin-bottom: 5px; /* Mantém espaçamento uniforme */
  min-height: 50px; /* Garante que todos tenham o mesmo tamanho */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-description {
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.5;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  text-align: center;
  min-height: 160px; /* Garante que as descrições tenham altura uniforme */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-button {
  background-color: #4A2391; /* Roxo */
   color: white;
   border: none;
   padding: 10px 20px;
   border-radius: 30px;
   cursor: pointer;
   font-size: 1em;
   font-weight: 600;
   margin-top: 20px; /* Reduz o espaço acima do botão */
   width: 80%; /* Define um tamanho consistente */
   max-width: 150px; /* Limita o tamanho máximo */
   text-decoration: none; /* Remove sublinhado ou linha branca */
   filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
   transition: background-color 0.3s, transform 0.2s;
}

.card-button:hover {
  background-color: #202020;
}

.card-price {
  display: inline; /* Alinha os preços na mesma linha */
  gap: 8px; /* Espaçamento entre o preço antigo e novo */
  white-space: nowrap; /* Evita que os preços quebrem para outra linha */
  margin: 5px 0; /* Ajusta espaçamento entre os preços e outros textos */
}

.old-price {
  text-decoration: line-through; /* Riscado */
  color: #aaa; /* Cinza */
  font-size: 1rem;
}

.new-price {
  color: #ff0; /* Amarelo */
  font-weight: bold;
  font-size: 1.1rem;
  animation: blink 1.5s infinite; /* Mantém a animação do preço */
}

@keyframes blink {
  0%, 100% { color: #ff0; } /* Amarelo */
  50% { color: #fff; }      /* Branco */
}

@media (max-width: 768px) {
  .cards-container {
    justify-content: center;
  }

  .card {
    width: 90%; /* Ajusta a largura para 90% da tela */
    max-width: 400px; /* Limita o tamanho máximo */
    height: auto; /* Permite altura dinâmica para telas menores */
  }

  .card-icon img {
    width: 100%; /* Faz com que a imagem ocupe toda a largura do card */
    height: auto; /* Ajusta a altura da imagem proporcionalmente */
  }

  .card-icon {
    margin-bottom: 5px; /* Espaço entre a imagem e o título */
  }

  .card-button {
    width: 60%; /* Botão ocupa quase toda a largura */
    margin-top: 30px;
  }

  .card-title {
    margin-top: 20px;
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .cards-container {
    justify-content: center;
  }

  .card {
    width: 80%; /* Ajusta a largura dos cards para 80% da tela */
    max-width: 350px; /* Limita o tamanho máximo dos cards */
    height: auto; /* Permite altura dinâmica */
  }

  .card-description {
    font-size: 0.87rem; /* Ajusta o tamanho da fonte */
  }

  .card-icon img {
    width: 80%; /* Diminui a largura da imagem */
    height: auto; /* Ajusta a altura da imagem proporcionalmente */
  }

  .card-button {
    width: 60%; /* Botão ocupa toda a largura */
    max-width: 250px;
    margin-top: 30px;
  }

  .card-title {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .card-icon {
    margin-bottom: 5px; /* Espaço entre a imagem e o título */
  }
}
