@font-face {
  font-family: 'octopus'; /* Nome que você escolher */
  src: url('../fonts/octopus.otf') format('truetype');
}

body {
  font-family: "Inter", sans-serif;
  background-color: #1A1A1A;
  color: #ffffff;
  margin: 0; /* Remove margens externas */
  padding: 0;
}

.h2 {
  font-weight: 800;
  font-size: 1.8rem;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  margin-bottom: 30px;
}

.coach-section {
  text-align: center;
  background-color: #222222; /* Cor de fundo */
  color: #fff; /* Cor do texto */
  padding: 1rem 1rem; /* Ajuste de padding para mais espaçamento interno */
  margin-top: 10px;
}

.coach-cards {
  display: flex;
  justify-content: center;
  gap: 50px; /* Ajuste para espaçamento consistente entre os cards */
  flex-wrap: wrap;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  margin-bottom: 20px;
}

.coach-card {
  background-color: #191919; /* Fundo do card */
  border-radius: 30px;
  padding: 1.5rem; /* Mais padding interno */
  max-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Garantir alinhamento vertical */
  align-items: center; /* Centralizar conteúdo */
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  gap: 1rem; /* Espaçamento entre os elementos internos */
}

.coach-card img {
  width: 250px; /* Define um tamanho consistente para imagens */
  height: 300px;
  object-fit: cover; /* Preenchimento consistente */
  border-radius: 50%; /* Circular */
  margin-bottom: 1rem;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
}

.coach-card h3 {
  font-weight: 800;
  font-size: 1.5rem;
  color: #ffffff;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  margin: 0; /* Remove margens extras */
}

.coach-card p {
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0; /* Remove margens extras */
  text-align: center; /* Melhor alinhamento para textos longos */
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
}

