
@font-face {
  font-family: 'octopus'; /* Nome que você escolher */
  src: url('../fonts/octopus.otf') format('truetype');
}

.promo-banner {
    background-color: #1A1A1A; /* Fundo preto */
    color: #ffffff; /* Texto branco */
    text-align: center; /* Centralizar conteúdo */
    padding: 40px 20px;
    border-radius: 0%; /* Bordas arredondadas */
    margin: 20px auto;
    max-width: 1200px;
  }
  
  /* Título principal */
  .promo-title {
    font-size: 2em;
    font-weight: 800;
    color: #9F63DD; /* Roxo */
    margin-bottom: 20px;
    line-height: 1.4;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  /* Descrição */
  .promo-description {
    font-size: 1.1em;
    font-weight: 200;
    margin-bottom: 30px;
    margin: 0 auto;
    max-width: 750px;
    margin-bottom: 30px;
    text-align: center;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  /* Botão */
  .promo-button {
    background-color: #4A2391; /* Roxo escuro */
    color: #ffffff;
    font-size: 1em;
    text-decoration: none; /* Remove o sublinhado dos links */
    font-weight: bold;
    padding: 12px 25px;
    border: none;
    border-radius: 30px; /* Bordas arredondadas no botão */
    cursor: pointer;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
    transition: background-color 0.3s, transform 0.2s;
  }
  
  /* Efeito de hover no botão */
  .promo-button:hover {
    background-color: #8e44ad; /* Roxo mais claro no hover */
    transform: scale(1.05); /* Leve aumento no tamanho */
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }

  /* Responsividade para telas médias (tablets) */
@media (max-width: 768px) {
  .promo-banner {
      padding: 30px 15px;
  }

  .promo-title {
      font-size: 1.8em; /* Reduz tamanho do título */
  }

  .promo-description {
      font-size: 1em; /* Reduz tamanho da descrição */
      max-width: 600px; /* Ajusta largura máxima */
  }

  .promo-button {
      font-size: 0.9em; /* Ajusta o botão */
      padding: 10px 20px;
  }
}

/* Responsividade para telas pequenas (smartphones) */
@media (max-width: 480px) {
  .promo-banner {
      padding: 20px 10px; /* Reduz espaçamento geral */
  }

  .promo-title {
      font-size: 1.01em; /* Menor título */
      margin-bottom: 15px;
  }

  .promo-description {
      font-size: 0.9em; /* Menor fonte para descrição */
      max-width: 100%; /* Ocupa toda a largura do contêiner */
  }

  .promo-button {
      font-size: 0.8em; /* Reduz tamanho do botão */
      padding: 8px 18px;
  }
}
  