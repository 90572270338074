.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1a1a1a; /* Fundo escuro */
    color: #ffffff; /* Cor do texto */
    padding: 20px 45px; /* Espaçamento interno */
  }
  
  .footer-left {
    display: flex;
    align-items: center; /* Alinha a logo e texto na mesma linha */
    gap: 15px; /* Espaço entre logo e texto */
  }
  
  .footer-logo {
    width: 80px; /* Ajuste conforme necessário */
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .footer-left p {
    font-size: 0.9em;
    line-height: 1.5;
    color: #ffffff;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .footer-left .footer-link {
    color: #ffffff; /* Cor roxa para o link */
    text-decoration: none; /* Remove o sublinhado */
    transition: color 0.3s ease;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .footer-left .footer-link:hover {
    color: #9F63DD; /* Cor ao passar o mouse */
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .footer-right {
    display: flex;
    gap: 15px; /* Espaçamento entre ícones */
  }
  
  .footer-right a {
    font-size: 1.5em;
    color: #ffffff;
    transition: color 0.3s ease;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .footer-right a:hover {
    color: #9F63DD; /* Cor roxa ao passar o mouse */
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-left {
      flex-direction: column; /* Coloca a logo e o texto um abaixo do outro */
      align-items: center;
      margin-bottom: 20px; /* Adiciona espaço entre a logo e os ícones */
    }
  
    .footer-right {
      gap: 10px;
    }
  }
  