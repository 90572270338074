@font-face {
  font-family: 'octopus'; /* Nome que você escolher */
  src: url('../fonts/octopus.otf') format('truetype');
}

.dominus-container {
    background-color: #5B299C;
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .dominus-title {
    font-size: 2em;
    font-weight: 800;
    margin-bottom: 30px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .dominus-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 45px;
    margin-bottom: 20px;
  }
  
  .dominus-card {
    background-color: #202020;
    border-radius: 30px;
    padding: 20px;
    max-width: 450px;
    text-align: center;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .dominus-card-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: white;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .dominus-card-content {
    font-weight: 200;
    font-size: 0.9em;
    line-height: 1.5;
    color: #ffffff;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  @media (max-width: 768px) {
    .dominus-title {
      font-size: 1.8em;
      margin-bottom: 20px;
    }
  
    .dominus-cards {
      gap: 30px;
    }
  
    .dominus-card {
      max-width: 90%; /* Cards ocupam quase toda a largura da tela */
      padding: 15px;
    }
  
    .dominus-card-title {
      font-size: 1.3em;
    }
  
    .dominus-card-content {
      font-size: 0.85em;
    }
  }
  
  @media (max-width: 480px) {
    .dominus-title {
      font-size: 1.5em;
      margin-bottom: 15px;
    }
  
    .dominus-cards {
      gap: 20px;
    }
  
    .dominus-card {
      max-width: 100%; /* Cards ocupam 100% da largura da tela */
      padding: 10px;
    }
  
    .dominus-card-title {
      font-size: 1.2em;
    }
  
    .dominus-card-content {
      font-size: 0.8em;
    }
  }