.faq-container {
    display: flex;
    justify-content: space-between;
    background-color: #1a1a1a; /* Cor de fundo escura */
    color: #ffffff; /* Cor do texto */
    padding: 45px;
  }
  
  .faq-left {
    width: 25%;
  }
  
  .faq-right {
    width: 55%;
  }
  
  h2, h3 {
    font-size: 2em;
    font-weight: 800;
    color: #ffffff;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1em;
    font-weight: 200;
    margin-bottom: 5px;
    line-height: 1.6;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .contact {
    display: flex;
    flex-direction: column; /* Alinha os itens verticalmente */
    gap: 15px; /* Espaço entre os blocos de email e WhatsApp */
  }
  
  .contact .email, .contact .whatsapp {
    display: flex;
    flex-direction: column;
    gap: 3px; /* Espaço entre o título e o conteúdo */
  }
  
  .contact a {
    font-size: 1.5em;
    font-weight: 800;
    color: #9F63DD; /* Cor roxa */
    text-decoration: none; /* Remove o sublinhado */
    transition: color 0.3s ease;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .contact a:hover {
    color: #8e44ad; /* Cor ao passar o mouse */
  }
  
  .contact p {
    margin: 0;
  }
  
  .contact p strong {
    font-size: 2em;
    font-weight: 800;
    color: #ffffff;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .faq-right h3 {
    border-bottom: 1px solid #ffffff;
    padding-bottom: 10px;
    margin-bottom: 10px;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  }
  
  .faq-right p {
    margin-bottom: 20px;
  }
  
  /* Responsividade */
@media (max-width: 768px) { /* Tablets */
    .faq-container {
      flex-direction: column; /* Organiza em uma única coluna */
      padding: 20px;
    }
  
    .faq-left,
    .faq-right {
      width: 100%; /* Largura total em telas menores */
      margin-bottom: 20px;
    }
  
    h2, h3 {
      font-size: 1.8em; /* Ajusta o tamanho dos títulos */
    }
  
    p {
      font-size: 0.95em; /* Reduz o tamanho do texto */
    }
  
    .contact a {
      font-size: 1.3em; /* Ajusta o tamanho dos links */
    }
  
    .contact p strong {
      font-size: 1.5em; /* Ajusta o tamanho do título da seção */
    }
  }
  
  @media (max-width: 480px) { /* Celulares */
    .faq-container {
      padding: 15px;
    }
  
    h2, h3 {
      font-size: 1.5em; /* Reduz ainda mais os títulos */
    }
  
    p {
      font-size: 0.9em; /* Reduz o tamanho do texto */
    }
  
    .contact a {
      font-size: 1.1em; /* Ajusta o tamanho dos links */
    }
  
    .contact p strong {
      font-size: 1.3em; /* Reduz o tamanho do título */
    }
  }