/* Estilo geral */
@font-face {
  font-family: 'octopus'; /* Nome que você escolher */
  src: url('../fonts/octopus.otf') format('truetype');
}

body {
  margin: 0;
  font-family: "octopus", sans-serif;
  background-color: #1A1A1A;
  color: #ffffff;
}

/* Cabeçalho */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.header-title {
  font-family: 'octopus', sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  color: #9F63DD;
  text-transform: uppercase;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  text-align: center; /* Centraliza o título */
}

/* Vídeo */
.header-video {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  border: 10px solid #9F63DD; /* Borda roxa que acompanha o vídeo */
  border-radius: 8px;
  width: 100%;  /* O vídeo ocupa 100% da largura disponível */
  max-width: 1100px; /* Largura máxima do vídeo */
  aspect-ratio: 16 / 9; /* Proporção 16:9 */
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.705));
  display: flex;
  justify-content: center; /* Centraliza na horizontal */
  align-items: center; /* Centraliza na vertical */
  overflow: hidden; /* Garante que nada ultrapasse os limites */
}

/* Garantir que o iframe ocupe 100% do espaço, com 16:9 fixo */
.header-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;  /* Garante que o vídeo ocupe toda a largura */
  height: 100%; /* Garante que a altura seja proporcional */
}

/* Responsividade */
@media (max-width: 1200px) {
  .header-title {
    font-size: 2.5rem;  /* Ajusta o tamanho do título para telas menores */
  }
  .header-video {
    width: 100%;  /* O vídeo ocupa 100% da largura em telas menores */
    max-width: 1280px; /* Respeita a largura máxima do vídeo */
    aspect-ratio: 16 / 9; /* Mantém a proporção 16:9 */
  }
}

@media (max-width: 768px) {
  .header-title {
    font-size: 2.5rem;  /* Ajusta o tamanho do título para telas menores */
  }
  .header-video {
    width: 100%;  /* O vídeo ocupa 50% da largura da tela em tablets */
    max-width: 640px; /* Garante que o vídeo não ultrapasse 640px de largura */
    aspect-ratio: 16 / 9; /* Mantém a proporção 16:9 */
  }
}

@media (max-width: 480px) {
  .header-title {
    font-size: 2.5rem;  /* Ajusta o tamanho do título para telas pequenas */
    padding: 0 10px;    /* Adiciona padding para não cortar o texto em telas muito pequenas */
  }
  .header-video {
    width: 100%;  /* O vídeo ocupa 50% da largura em telas de celulares */
    max-width: 640px; /* Garante que o vídeo não ultrapasse 640px de largura */
    aspect-ratio: 16 / 9; /* Mantém a proporção 16:9 */
  }
}